<template>
    <v-container fluid no-gutters class="my-0 py-0">
        <filters :cup="true" @getByTeam="getScheduleByTeam" @getByFilters="getByFilters" />
        <schedule-match-center :matches="matches" />
    </v-container>
</template>

<script>
    import ScheduleMatchCenter from '@/components/ScheduleMatchCenter'
    import Filters from '@/components/GenericFilters'
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'

    export default {
        components: { ScheduleMatchCenter, Filters },
        data() {
            return {
                headers: [
                    {
                        text: 'Pos.',
                        align: 'start',
                        sortable: false,
                        value: 'position'
                    },
                    { text: 'Club', value: 'club' },
                    { text: 'GP', value: 'gp' },
                    { text: 'Won', value: 'won' },
                    { text: 'Lost', value: 'lost' },
                    { text: 'Win(%)', value: 'win_percentage' },
                    { text: 'Pts+', value: 'pts_pos' },
                    { text: 'Pts-', value: 'pts_neg' },
                    { text: '+/-', value: 'pts_diff' },
                    { text: 'Last 5', value: 'last_5' }
                ],
                matches: [],
                filter: {}
            }
        },
        methods: {
            getScheduleByTeam(round = null, arenaId = null, isCup = null, leagueId = null, teamId = null) {
                this.filter = {}
                if (round !== null) {
                    this.filter.round = `round=${round}`
                }
                if (arenaId !== null) {
                    this.filter.arena = `arenaId=${arenaId}`
                }
                if (isCup !== null) {
                    this.filter.league = `isCup=${isCup}`
                }
                if (leagueId !== null) {
                    this.filter.league = `leagueId=${leagueId}`
                }
                matchService.getMatchesByTeam(teamId, this.filter).then(resp => {
                    this.matches = resp.data
                    this.getMatchesDesc(this.matches)
                })
            },
            getMatchesDesc() {
                this.matches.sort(function(a, b) {
                    if (momentTimezone(b.matchDatetime).isAfter(momentTimezone(a.matchDatetime))) {
                        return 1
                    } else {
                        return -1
                    }
                })
            },
            getScheduleByLeague(leagueId) {
                this.filter = {}
                this.filter.league = `"league":"${leagueId}"`
                matchService.getMatches(1, 100, this.filter).then(resp => {
                    this.matches = resp.data.content
                    this.getMatchesDesc(this.matches)
                })
            },
            getCupMatches() {
                this.filter = {}
                this.filter.league = `"isCup":${true}`
                matchService.getMatches(1, 100, this.filter).then(resp => {
                    this.matches = resp.data.content
                    this.getMatchesDesc(this.matches)
                })
            },
            getScheduleByArena(arenaId) {
                this.filter = {}
                this.filter.arena = `"arena":"${arenaId}"`
                matchService.getMatches(1, 100, this.filter).then(resp => {
                    this.matches = resp.data.content
                    this.getMatchesDesc(this.matches)
                })
            },
            getByFilters(round = null, arenaId = null, isCup = null, leagueId = null, seasonId = null) {
                this.filter = {}
                if (round !== null) {
                    this.filter.round = `"round":"${round}"`
                }
                if (arenaId !== null) {
                    this.filter.arena = `"arena":"${arenaId}"`
                }
                if (isCup !== null) {
                    this.filter.league = `"isCup":${isCup}`
                }
                if (leagueId !== null) {
                    this.filter.league = `"league":"${leagueId}"`
                }
                if (seasonId !== null) {
                    this.filter.team = `"season":"${seasonId}"`
                }
                matchService.getMatches(1, 100, this.filter).then(resp => {
                    this.matches = resp.data.content
                    this.getMatchesDesc(this.matches)
                })
            }
        }
    }
</script>

<style scoped>
.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background-color: orange;
    color: white;
}
</style>
